import React from "react";

interface BannerProps {
  text: string;
  banner: string;
}
const Banner = ({ banner, text }: BannerProps) => {
  return (
    <div className="relative">
      <img
        className="w-screen"
        src={banner}
        alt="Nuestra historia Ilaini"
      // style={{ height: "calc(100vw * 0.40)" }}
      />
      <h2 className="font-eur42 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-6xl lg:text-8xl"

      >
        {text}
      </h2>
    </div>
  );
};

export default Banner;
