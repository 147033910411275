import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
import { Type } from "./typeService";


export interface Section {
  id: number;
  name: string;
  description: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null | string;
  type: Type[];
}
export type GeneralResponse = {
  message: string;
  data: Section[] | Section;
};

type GeneralResponseHandler = (data: GeneralResponse) => void;

export const sectionsTypesPublished = async (
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/sections/types",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );
export const getSection = async (id: number, resultHandler: GeneralResponseHandler, errorHandler: ErrorHandler) =>
  myFetch<GeneralResponse>(`admin/sections/${id}`, '', resultHandler, errorHandler, HttpMethod.GET)


export const getProductsSectionsById = async (
  id: number,
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    `shoppings/products/section/${id}?establishmentId=1`,
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );
