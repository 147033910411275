import { myFetch, ErrorHandler, HttpMethod } from "./baseService";

export interface SubType {
  id: number;
  name: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null;
}


export type GeneralResponse = {
  message: string;
  data: SubType[] | SubType;
};

type GeneralResponseHandler = (data: GeneralResponse) => void;


export const getSubType = async (id: number, resultHandler: GeneralResponseHandler, errorHandler: ErrorHandler) =>
  myFetch<GeneralResponse>(`sub-type/${id}`, '', resultHandler, errorHandler, HttpMethod.GET)
