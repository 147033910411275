import { useEffect, useState } from "react";
import { Product, productsPublished } from "../../services/productsService";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import QuickView from "./QuickView";
import { useCurrencySweet } from "../../services/contexts/useCurrencySweet";
import Skeleton from "react-loading-skeleton";
const ProductsNewSeason = () => {

    const [products, setProducts] = useState<Product[]>([]);
    const [productData, setProductData] = useState<Product>();
    const [
        { currencyInformation }, { }] = useCurrencySweet();
    useEffect(() => {
        const initialProducts = () => {
            productsPublished(
                ({ data }) => setProducts(data),
                (err) => console.error(err)
            );
        }
        initialProducts()
        return () => {
            initialProducts()
        };
    }, []);
    return (
        <section id="carousel-products" className="products-carousel container">
            <div className="scrollable-container">
                <div className="row scrollable-row">
                    {products.map((product: Product) => (
                        <>
                            <div className="col-md-3 col-sm-3 col-12 mb-3">
                                <img
                                    src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[0]?.imagenPath || "")}
                                    className="img-fluid" alt="Imagen 1" />
                                <button
                                    onClick={() => setProductData(product)}
                                    className="btn btn-primary flex-grow-1 fs-base ps-0 pe-3 pe-xxl-4 border-0 text-uppercase fw-400 js-quick-view w-100 mt-2"
                                    data-bs-toggle="modal" data-bs-target="#quickView" title="Quick view"
                                    style={{ backgroundColor: "#EFE2CF", color: "#743F05", letterSpacing: 3 }}>
                                    Añadir al carrito</button>
                            </div>
                        </>
                    ))}
                    <div className="col-md-3 col-sm-6 col-12 mb-3">
                        <img
                            src={process.env.REACT_APP_API_URL + (products[0]?.imagePath.length && products[0]?.imagePath[0]?.imagenPath || "")}
                            className="img-fluid" alt="Imagen 1" />
                        <button
                            onClick={() => setProductData(products[0])}
                            className="btn btn-primary flex-grow-1 fs-base ps-0 pe-3 pe-xxl-4 border-0 text-uppercase fw-400 js-quick-view w-100 mt-2"
                            data-bs-toggle="modal" data-bs-target="#quickView" title="Quick view"
                            style={{ backgroundColor: "#EFE2CF", color: "#743F05", letterSpacing: 3 }}>
                            Añadir al carrito</button>
                    </div>
                </div>
            </div>

            <QuickView product={productData} />
        </section >
        //      <section id="carousel-products" className="products-carousel container">
        //      <div className="scrollable-container">
        //          <div className="row scrollable-row">
        //              {products.map((product: Product) => (
        //                  <>
        //                      <div className="col-md-3 col-sm-3 col-12 mb-3">
        //                          <img
        //                              src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[0]?.imagenPath || "")}
        //                              className="img-fluid" alt="Imagen 1" />
        //                          <button
        //                              onClick={() => setProductData(product)}
        //                              className="btn btn-primary flex-grow-1 fs-base ps-0 pe-3 pe-xxl-4 border-0 text-uppercase fw-400 js-quick-view w-100 mt-2"
        //                              data-bs-toggle="modal" data-bs-target="#quickView" title="Quick view"
        //                              style={{ backgroundColor: "#EFE2CF", color: "#743F05", letterSpacing: 3 }}>
        //                              Añadir al carrito</button>
        //                      </div>
        //                  </>
        //              ))}
        //              <div className="col-md-3 col-sm-6 col-12 mb-3">
        //                  <img
        //                      src={process.env.REACT_APP_API_URL + (products[0]?.imagePath.length && products[0]?.imagePath[0]?.imagenPath || "")}
        //                      className="img-fluid" alt="Imagen 1" />
        //                  <button
        //                      onClick={() => setProductData(products[0])}
        //                      className="btn btn-primary flex-grow-1 fs-base ps-0 pe-3 pe-xxl-4 border-0 text-uppercase fw-400 js-quick-view w-100 mt-2"
        //                      data-bs-toggle="modal" data-bs-target="#quickView" title="Quick view"
        //                      style={{ backgroundColor: "#EFE2CF", color: "#743F05", letterSpacing: 3 }}>
        //                      Añadir al carrito</button>
        //              </div>
        //          </div>
        //      </div>

        //      <QuickView product={productData} />
        //  </section >
    )
}
export default ProductsNewSeason
