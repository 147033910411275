import imageCorporateSale from "../../assets/images/corporate_sales.jpg";
const CorporateSales = () => {
  return (
    <>
      <div className="relative">
        <img
          className="w-screen"
          src={imageCorporateSale}
          alt="Nuestra historia Ilaini"
        />
        <h2 className="font-eur42 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center text-8xl"
        >
          Ventas <br /> Corporativas
        </h2>
        <div className="absolute  bg-[#EFE2CF] py-2 px-10 top-1/2 mt-48 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center">
          <span className="font-eur42 text-primaryColor  tracking-widestcustom uppercase">
            Encuéntranos
          </span>
        </div>
      </div>
    </>
  );
};

export default CorporateSales;
