import { useEffect, useState } from "react";
import { Config, configuration } from "../services/configService";
import { Section, getProductsSectionsById, getSection, sectionsTypesPublished } from "../services/sectionsService";
import HeaderLayout from "../components/layouts/HeaderLayout";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FullScreenLoader from "../components/utils/FullScreenLoader";
import { Client, login, register } from "../services/clientService";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Product } from "../services/productsService";
import { Type } from "../services/typeService";

const SectionProducts = () => {
  const [showForm, setShowForm] = useState(false)
  const [section, setSection] = useState<Section>()
  const [productList, setProductList] = useState<Product[]>([])

  let { id } = useParams();


  useEffect(() => {
    return () => {
      getProductsSectionsById(Number(id), (response) => setProductList(response.data as Product[]), error => console.error(error))
      getSection(Number(id), (response) => setSection(response.data as Section), error => console.error(error))
    }
  }, [])

  const formikRegister = useFormik<Client>({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Requerido'),
      email: Yup.string().required('Requerido'),
      password: Yup.string().required('Requerido'),
    }),
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      register(values, (data) => {
        toast.success(data.message)
        setShowForm(true)
        resetForm()
      }, (error) => toast.error(error))
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitting(false)
    },
  });

  const formikLogin = useFormik<Client>({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Requerido'),
      password: Yup.string().required('Requerido'),
    }),
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      login(values, (data) => {
        toast.success(data.message)
        resetForm()
      }, (error) => toast.error(error))
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitting(false)
    },
  });

  return (
    <>
      {formikRegister.isSubmitting && <FullScreenLoader />}
      <section>
        <div style={{ borderColor: "#eeeeee" }}>
          <div className="shop-banner position-relative ">
            <div className="background-img background-img_overlay" style={{ backgroundColor: "#eeeeee" }}>
              <img loading="lazy" src={require("../assets/images/shop_banner6.jpg")} width="1903" height="420" alt="Pattern" className="slideshow-bg__img object-fit-cover" />
            </div>

            <div className="shop-banner__content container position-absolute start-50 top-50 translate-middle">
              <h2 className="h1 text-uppercase text-white text-center fw-bold mb-3 mb-xl-4 mb-xl-5">{section?.name}</h2>
              <ul className="d-flex flex-wrap justify-content-center list-unstyled text-uppercase h6" aria-label="Collections List">
                {section?.type.map((type: Type) => (
                  <li className="me-3 me-xl-4 pe-1"><a href={"/type/" + type.id} className="menu-link menu-link_us-s text-white">{type.name}</a></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section >

      <div className="mb-4 pb-lg-3"></div>

      <section className="shop-main container">
        <div className="d-flex justify-content-between mb-4 pb-md-2">
          <div className="breadcrumb mb-0 d-none d-md-block flex-grow-1">
            <a href="#" className="menu-link menu-link_us-s text-uppercase fw-medium" >Home</a>
            <span className="breadcrumb-separator menu-link fw-medium ps-1 pe-1">/</span>
            <a href="#" className="menu-link menu-link_us-s text-uppercase fw-medium" >The Shop</a>
          </div>

          <div className="shop-acs d-flex align-items-center justify-content-between justify-content-md-end flex-grow-1">
            <select className="shop-acs__select form-select w-auto border-0 py-0 order-1 order-md-0" aria-label="Sort Items" name="total-number">
              <option selected>Default Sorting</option>
              <option value="1">Featured</option>
              <option value="2">Best selling</option>
              <option value="3">Alphabetically, A-Z</option>
              <option value="3">Alphabetically, Z-A</option>
              <option value="3">Price, low to high</option>
              <option value="3">Price, high to low</option>
              <option value="3">Date, old to new</option>
              <option value="3">Date, new to old</option>
            </select>

            <div className="shop-asc__seprator mx-3 bg-light d-none d-md-block order-md-0"></div>

            <div className="col-size align-items-center order-1 d-none d-lg-flex">
              <span className="text-uppercase fw-medium me-2">View</span>
              <button className="btn-link fw-medium me-2 js-cols-size" data-target="products-grid" data-cols="2">2</button>
              <button className="btn-link fw-medium me-2 js-cols-size" data-target="products-grid" data-cols="3">3</button>
              <button className="btn-link fw-medium js-cols-size" data-target="products-grid" data-cols="4">4</button>
            </div>

            <div className="shop-asc__seprator mx-3 bg-light d-none d-lg-block order-md-1"></div>

            <div className="shop-filter d-flex align-items-center order-0 order-md-3">
              <button className="btn-link btn-link_f d-flex align-items-center ps-0 js-open-aside" data-aside="shopFilter">
                <svg className="d-inline-block align-middle me-2" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><use href="#icon_filter" /></svg>
                <span className="text-uppercase fw-medium d-inline-block align-middle">Filter</span>
              </button>
            </div>
          </div>
        </div>

        <div className="products-grid row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5" id="products-grid" >
          {productList.map(product => (
            <div className="product-card-wrapper">
              <div className="product-card mb-3 mb-md-4 mb-xxl-5">
                <div className="pc__img-wrapper">
                  <a href="./product1_simple.html">
                    <img loading="lazy"
                      src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[0].imagenPath || "")}
                      width="330" height="400"
                      alt={product.name + "1"} className="pc__img" />
                    <img loading="lazy"
                      src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[1].imagenPath || "")}
                      width="330" height="400"
                      alt={product.name + "2"} className="pc__img pc__img-second" />
                  </a>
                  <div className="product-label bg-white">Nuevo</div>
                  <div
                    className="anim_appear-bottom position-absolute bottom-0 start-0 w-100 d-none d-sm-flex align-items-center">
                    <button
                      className="btn btn-primary flex-grow-1 fs-base ps-3 ps-xxl-4 pe-0 border-0 text-uppercase fw-medium js-add-cart js-open-aside"
                      data-aside="cartDrawer" title="Add To Cart">Añadir al carrito</button>
                    <button
                      // onClick={() => setProductData(product)}
                      className="btn btn-primary flex-grow-1 fs-base ps-0 pe-3 pe-xxl-4 border-0 text-uppercase fw-medium js-quick-view"
                      data-bs-toggle="modal" data-bs-target="#quickView" title="Quick view">Vista rápida</button>
                  </div>
                  <button
                    className="pc__btn-wl position-absolute bg-body rounded-circle border-0 text-primary js-add-wishlist"
                    title="Add To Wishlist">
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <use href="#icon_heart" /></svg>
                  </button>
                </div>

                <div className="pc__info position-relative">
                  <p className="pc__category">{ }</p>
                  <h6 className="pc__title"><a href="./product1_simple.html">{product.name}</a></h6>
                  <div className="product-card__price d-flex">
                    <span className="money price">${product.salePrice}</span>

                  </div>

                  <div className="d-flex align-items-center mt-1">
                    {product.colors.map((color) => (
                      <a href="#" className="swatch-color pc__swatch-color" style={{ color: color.code }}></a>
                    ))}
                  </div>

                  <button className="pc__btn-wl position-absolute top-0 end-0 bg-transparent border-0 js-add-wishlist" title="Add To Wishlist">
                    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><use href="#icon_heart" /></svg>
                  </button>
                </div>

                <div className="pc-labels position-absolute top-0 start-0 w-100 d-flex justify-content-between">
                  <div className="pc-labels__right ms-auto">
                    <span className="pc-label pc-label_sale d-block text-white">-67%</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="mb-1 text-center fw-medium">SHOWING 36 of 497 items</p>
        <div className="progress progress_uomo mb-3 ms-auto me-auto" style={{ width: 300 }}>
          {/* <div className="progress-bar" role="progressbar" style={{ width: "39%" }} aria-valuenow="39" aria-valuemin="0" aria-valuemax="100"></div> */}
        </div>
        <div className="text-center">
          <a className="btn-link btn-link_lg text-uppercase fw-medium" href="#">Show More</a>
        </div>
      </section>
    </>
  );
};

export default SectionProducts;
