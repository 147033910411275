import { myFetch, ErrorHandler, HttpMethod } from "./baseService";

export interface Client {
  firstName?: string;
  lastName?: string;
  email: string;
  password?: string;
}

export type GeneralResponse = {
  message: string;
  data: Client;
};

type GeneralResponseHandler = (data: GeneralResponse) => void;


export const getToken = async (
  form: { code: string | null },
  resultHandler: any,
  errorHandler: ErrorHandler
) =>
  myFetch<{
    message: string;
    data: { token: string };
  }>(
    "client/auth/google/getToken",
    JSON.stringify(form),
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );
export const register = async (
  form: Client,
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "client/auth/register",
    JSON.stringify(form),
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );

export const login = async (
  form: Client,
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "client/auth/login",
    JSON.stringify(form),
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );
