import { useEffect, useState } from "react";

import { Section, sectionsTypesPublished } from "../../services/sectionsService";


const FindUs = () => {
  const [, setSections] = useState<Section[]>([]);

  const [showInfoPeru, setShowInfoPeru] = useState<boolean>(false);
  const [showInfoNewZealand, setShowInfoNewZealand] = useState<boolean>(false);

  const toggleInfoPeru = () => {
    setShowInfoPeru(!showInfoPeru);
  };

  const toggleInfoNewZealand = () => {
    setShowInfoNewZealand(!showInfoNewZealand);
  };

  useEffect(() => {
    const initial = () => {
      sectionsTypesPublished(
        ({ data }) => setSections(data as Section[]),
        (err) => console.error(err)
      );
    }
    initial()
    return () => {
      initial()
    };
  }, []);

  return (
    <>
      <section className="google-map mb-5">
        <h2 className="d-none">Contact US</h2>
        <div id="map" className="google-map__wrapper"></div>
      </section>
      <div className="container text-primaryColor">
        <h2 className="text-primaryColor mt-8 text-xl uppercase">
          Encuentranos
        </h2>

        <div className="lg:ml-40 lg:mr-40 x1280:ml-64 x1280:mr-64 mt-8 border-t-2 border-primaryColor">
          <div className="mt-4 px-8">
            <div>
              <div className="mb-4 flex items-center justify-between">
                <h2 className="uppercase text-primaryColor font-bold text-xl">
                  Peru (01)
                </h2>
                <button className="text-2xl" onClick={toggleInfoPeru}>
                  {showInfoPeru ? "-" : "+"}
                </button>
              </div>
              {!showInfoPeru && (
                <div className="border-t border-primaryColor" />
              )}
              {showInfoPeru && (
                <div className="mt-4 grid md:grid-cols-3 grid-cols-1 items-center justify-center border-2 border-primaryColor p-4 text-sm">
                  <div className="flex items-center justify-center lg:justify-start">
                    <p>
                      Hotel 1 <span className="font-bold">Hotel Fundador</span>
                    </p>
                  </div>
                  <div className="flex flex-col mt-2 lg:mt-0 items-center justify-center">
                    <p>Campo redondo 109-111</p>
                    <p>San Lázaro, Arequipa - Peru</p>
                  </div>
                  <div className="flex mt-2 lg:mt-0 items-center justify-center lg:justify-end">
                    <p>(+51) 977528365</p>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="mb-4 mt-10 flex items-center justify-between">
                <h2 className="uppercase text-primaryColor font-bold text-xl">
                  Nueva Zelanda (01)
                </h2>
                <button className="text-2xl" onClick={toggleInfoNewZealand}>
                  {showInfoNewZealand ? "-" : "+"}
                </button>
              </div>
              {!showInfoNewZealand && (
                <div className="border-t-2 border-primaryColor" />
              )}

              {showInfoNewZealand && (
                <div className="mt-4 grid md:grid-cols-3 grid-cols-1 items-center justify-center border-2 border-primaryColor p-4 text-sm">
                  <div className="flex items-center justify-center lg:justify-start">
                    <p>
                      Hotel 1 <span className="font-bold">Hotel Fundador</span>
                    </p>
                  </div>
                  <div className="flex flex-col mt-2 lg:mt-0 items-center justify-center">
                    <p>Campo redondo 109-111</p>
                    <p>San Lázaro, Arequipa - Peru</p>
                  </div>
                  <div className="flex mt-2 lg:mt-0 items-center justify-center lg:justify-end">
                    <p>(+51) 977528365</p>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-10 flex justify-between">
              <p className="text-xl uppercase">Contacto</p>
              <div className="text-center">
                <p className="text-base">informes@ilaini.com</p>
                <p className="text-xs">Ventas generales y corporativas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindUs;
