const currencySymbols: any = [
    { PE: { code: "PEN", symbol: "S/." } },
    { AF: { code: "AFN", symbol: "؋" } },
    { AL: { code: "ALL", symbol: "L" } },
    { DZ: { code: "DZD", symbol: "د.ج" } },
    { AS: { code: "USD", symbol: "$" } },
    { AD: { code: "EUR", symbol: "€" } },
    { AO: { code: "AOA", symbol: "Kz" } },
    { AI: { code: "XCD", symbol: "EC$" } },
    { AQ: { code: "USD", symbol: "$" } },
    { AG: { code: "XCD", symbol: "EC$" } },
    { AR: { code: "ARS", symbol: "$" } },
    { AM: { code: "AMD", symbol: "֏" } },
    { AW: { code: "AWG", symbol: "ƒ" } },
    { AU: { code: "AUD", symbol: "$" } },
    { AT: { code: "EUR", symbol: "€" } },
    { AZ: { code: "AZN", symbol: "₼" } },
    { BS: { code: "BSD", symbol: "$" } },
    { BH: { code: "BHD", symbol: ".د.ب" } },
    { BD: { code: "BDT", symbol: "৳" } },
    { BB: { code: "BBD", symbol: "$" } },
    { BY: { code: "BYN", symbol: "Br" } },
    { BE: { code: "EUR", symbol: "€" } },
    { BZ: { code: "BZD", symbol: "BZ$" } },
    { BJ: { code: "XOF", symbol: "CFA" } },
    { BM: { code: "BMD", symbol: "$" } },
    { BT: { code: "BTN", symbol: "Nu." } },
    { BO: { code: "BOB", symbol: "Bs." } },
    { BQ: { code: "USD", symbol: "$" } },
    { BA: { code: "BAM", symbol: "КМ" } },
    { BW: { code: "BWP", symbol: "P" } },
    { BV: { code: "NOK", symbol: "kr" } },
    { BR: { code: "BRL", symbol: "R$" } },
    { IO: { code: "USD", symbol: "$" } },
    { BN: { code: "BND", symbol: "$" } },
    { BG: { code: "BGN", symbol: "лв" } },
    { BF: { code: "XOF", symbol: "CFA" } },
    { BI: { code: "BIF", symbol: "FBu" } },
    { CV: { code: "CVE", symbol: "$" } },
    { KH: { code: "KHR", symbol: "៛" } },
    { CM: { code: "XAF", symbol: "FCFA" } },
    { CA: { code: "CAD", symbol: "CA$" } },
    { KY: { code: "KYD", symbol: "$" } },
    { CF: { code: "XAF", symbol: "FCFA" } },
    { TD: { code: "XAF", symbol: "FCFA" } },
    { CL: { code: "CLP", symbol: "$" } },
    { CN: { code: "CNY", symbol: "¥" } },
    { CX: { code: "AUD", symbol: "$" } },
    { CC: { code: "AUD", symbol: "$" } },
    { CO: { code: "COP", symbol: "$" } },
    { KM: { code: "KMF", symbol: "CF" } },
    { CG: { code: "XAF", symbol: "FCFA" } },
    { CD: { code: "CDF", symbol: "FC" } },
    { CK: { code: "NZD", symbol: "$" } },
    { CR: { code: "CRC", symbol: "₡" } },
    { HR: { code: "HRK", symbol: "kn" } },
    { CU: { code: "CUP", symbol: "$" } },
    { CW: { code: "ANG", symbol: "ƒ" } },
    { CY: { code: "EUR", symbol: "€" } },
    { CZ: { code: "CZK", symbol: "Kč" } },
    { CI: { code: "XOF", symbol: "CFA" } },
    { DK: { code: "DKK", symbol: "kr" } },
    { DJ: { code: "DJF", symbol: "Fdj" } },
    { DM: { code: "XCD", symbol: "EC$" } },
    { DO: { code: "DOP", symbol: "RD$" } },
    { EC: { code: "USD", symbol: "$" } },
    { EG: { code: "EGP", symbol: "E£" } },
    { SV: { code: "USD", symbol: "$" } },
    { GQ: { code: "XAF", symbol: "FCFA" } },
    { ER: { code: "ERN", symbol: "Nfk" } },
    { EE: { code: "EUR", symbol: "€" } },
    { ET: { code: "ETB", symbol: "Br" } },
    { FK: { code: "FKP", symbol: "£" } },
    { FO: { code: "DKK", symbol: "kr" } },
    { FJ: { code: "FJD", symbol: "$" } },
    { FI: { code: "EUR", symbol: "€" } },
    { FR: { code: "EUR", symbol: "€" } },
    { GF: { code: "EUR", symbol: "€" } },
    { PF: { code: "XPF", symbol: "₣" } },
    { TF: { code: "EUR", symbol: "€" } },
    { GA: { code: "XAF", symbol: "FCFA" } },
    { GM: { code: "GMD", symbol: "D" } },
    { GE: { code: "GEL", symbol: "₾" } },
    { DE: { code: "EUR", symbol: "€" } },
    { GH: { code: "GHS", symbol: "₵" } },
    { GI: { code: "GIP", symbol: "£" } },
    { GR: { code: "EUR", symbol: "€" } },
    { GL: { code: "DKK", symbol: "kr" } },
    { GD: { code: "XCD", symbol: "EC$" } },
    { GP: { code: "EUR", symbol: "€" } },
    { GU: { code: "USD", symbol: "$" } },
    { GT: { code: "GTQ", symbol: "Q" } },
    { GG: { code: "GBP", symbol: "£" } },
    { GN: { code: "GNF", symbol: "FG" } },
    { GW: { code: "XOF", symbol: "CFA" } },
    { GY: { code: "GYD", symbol: "$" } },
    { HT: { code: "HTG", symbol: "G" } },
    { HM: { code: "AUD", symbol: "$" } },
    { VA: { code: "EUR", symbol: "€" } },
    { HN: { code: "HNL", symbol: "L" } },
    { HK: { code: "HKD", symbol: "HK$" } },
    { HU: { code: "HUF", symbol: "Ft" } },
    { IS: { code: "ISK", symbol: "kr" } },
    { IN: { code: "INR", symbol: "₹" } },
    { ID: { code: "IDR", symbol: "Rp" } },
    { IR: { code: "IRR", symbol: "﷼" } },
    { IQ: { code: "IQD", symbol: "ع.د" } },
    { IE: { code: "EUR", symbol: "€" } },
    { IM: { code: "GBP", symbol: "£" } },
    { IL: { code: "ILS", symbol: "₪" } },
    { IT: { code: "EUR", symbol: "€" } },
    { JM: { code: "JMD", symbol: "J$" } },
    { JP: { code: "JPY", symbol: "¥" } },
    { JE: { code: "GBP", symbol: "£" } },
    { JO: { code: "JOD", symbol: "JD" } },
    { KZ: { code: "KZT", symbol: "₸" } },
    { KE: { code: "KES", symbol: "KSh" } },
    { KI: { code: "AUD", symbol: "$" } },
    { KP: { code: "KPW", symbol: "₩" } },
    { KR: { code: "KRW", symbol: "₩" } },
    { KW: { code: "KWD", symbol: "KD" } },
    { KG: { code: "KGS", symbol: "сом" } },
    { LA: { code: "LAK", symbol: "₭" } },
    { LV: { code: "EUR", symbol: "€" } },
    { LB: { code: "LBP", symbol: "ل.ل" } },
    { LS: { code: "LSL", symbol: "L" } },
    { LR: { code: "LRD", symbol: "$" } },
    { LY: { code: "LYD", symbol: "ل.د" } },
    { LI: { code: "CHF", symbol: "CHF" } },
    { LT: { code: "EUR", symbol: "€" } },
    { LU: { code: "EUR", symbol: "€" } },
    { MO: { code: "MOP", symbol: "MOP$" } },
    { MK: { code: "MKD", symbol: "ден" } },
    { MG: { code: "MGA", symbol: "Ar" } },
    { MW: { code: "MWK", symbol: "MK" } },
    { MY: { code: "MYR", symbol: "RM" } },
    { MV: { code: "MVR", symbol: "ރ." } },
    { ML: { code: "XOF", symbol: "CFA" } },
    { MT: { code: "EUR", symbol: "€" } },
    { MH: { code: "USD", symbol: "$" } },
    { MQ: { code: "EUR", symbol: "€" } },
    { MR: { code: "MRU", symbol: "UM" } },
    { MU: { code: "MUR", symbol: "₨" } },
    { YT: { code: "EUR", symbol: "€" } },
    { MX: { code: "MXN", symbol: "$" } },
    { FM: { code: "USD", symbol: "$" } },
    { MD: { code: "MDL", symbol: "MDL" } },
    { MC: { code: "EUR", symbol: "€" } },
    { MN: { code: "MNT", symbol: "₮" } },
    { ME: { code: "EUR", symbol: "€" } },
    { MS: { code: "XCD", symbol: "EC$" } },
    { MA: { code: "MAD", symbol: "MAD" } },
    { MZ: { code: "MZN", symbol: "MT" } },
    { MM: { code: "MMK", symbol: "K" } },
    { NA: { code: "NAD", symbol: "$" } },
    { NR: { code: "AUD", symbol: "$" } },
    { NP: { code: "NPR", symbol: "रु" } },
    { NL: { code: "EUR", symbol: "€" } },
    { NC: { code: "XPF", symbol: "₣" } },
    { NZ: { code: "NZD", symbol: "$" } },
    { NI: { code: "NIO", symbol: "C$" } },
    { NE: { code: "XOF", symbol: "CFA" } },
    { NG: { code: "NGN", symbol: "₦" } },
    { NU: { code: "NZD", symbol: "$" } },
    { NF: { code: "AUD", symbol: "$" } },
    { MP: { code: "USD", symbol: "$" } },
    { NO: { code: "NOK", symbol: "kr" } },
    { OM: { code: "OMR", symbol: "ر.ع." } },
    { PK: { code: "PKR", symbol: "₨" } },
    { PW: { code: "USD", symbol: "$" } },
    { PS: { code: "ILS", symbol: "₪" } },
    { PA: { code: "PAB", symbol: "B/." } },
    { PG: { code: "PGK", symbol: "K" } },
    { PY: { code: "PYG", symbol: "₲" } },
    { PE: { code: "PEN", symbol: "S/." } },
    { PH: { code: "PHP", symbol: "₱" } },
    { PN: { code: "NZD", symbol: "$" } },
    { PL: { code: "PLN", symbol: "zł" } },
    { PT: { code: "EUR", symbol: "€" } },
    { PR: { code: "USD", symbol: "$" } },
    { QA: { code: "QAR", symbol: "ر.ق" } },
    { RE: { code: "EUR", symbol: "€" } },
    { RO: { code: "RON", symbol: "lei" } },
    { RU: { code: "RUB", symbol: "₽" } },
    { RW: { code: "RWF", symbol: "RF" } },
    { BL: { code: "EUR", symbol: "€" } },
    { SH: { code: "SHP", symbol: "£" } },
    { KN: { code: "XCD", symbol: "EC$" } },
    { LC: { code: "XCD", symbol: "EC$" } },
    { MF: { code: "EUR", symbol: "€" } },
    { PM: { code: "EUR", symbol: "€" } },
    { VC: { code: "XCD", symbol: "EC$" } },
    { WS: { code: "WST", symbol: "WS$" } },
    { SM: { code: "EUR", symbol: "€" } },
    { ST: { code: "STN", symbol: "Db" } },
    { SA: { code: "SAR", symbol: "ر.س" } },
    { SN: { code: "XOF", symbol: "CFA" } },
    { RS: { code: "RSD", symbol: "дин." } },
    { SC: { code: "SCR", symbol: "₨" } },
    { SL: { code: "SLL", symbol: "Le" } },
    { SG: { code: "SGD", symbol: "$" } },
    { SX: { code: "ANG", symbol: "ƒ" } },
    { SK: { code: "EUR", symbol: "€" } },
    { SI: { code: "EUR", symbol: "€" } },
    { SB: { code: "SBD", symbol: "$" } },
    { SO: { code: "SOS", symbol: "Sh" } },
    { ZA: { code: "ZAR", symbol: "R" } },
    { GS: { code: "FKP", symbol: "£" } },
    { SS: { code: "SSP", symbol: "£" } },
    { ES: { code: "EUR", symbol: "€" } },
    { LK: { code: "LKR", symbol: "රු" } },
    { SD: { code: "SDG", symbol: "ج.س." } },
    { SR: { code: "SRD", symbol: "$" } },
    { SJ: { code: "NOK", symbol: "kr" } },
    { SE: { code: "SEK", symbol: "kr" } },
    { CH: { code: "CHF", symbol: "CHF" } },
    { SY: { code: "SYP", symbol: "£" } },
    { TW: { code: "TWD", symbol: "NT$" } },
    { TJ: { code: "TJS", symbol: "ЅМ" } },
    { TZ: { code: "TZS", symbol: "TSh" } },
    { TH: { code: "THB", symbol: "฿" } },
    { TL: { code: "USD", symbol: "$" } },
    { TG: { code: "XOF", symbol: "CFA" } },
    { TK: { code: "NZD", symbol: "$" } },
    { TO: { code: "TOP", symbol: "T$" } },
    { TT: { code: "TTD", symbol: "TT$" } },
    { TN: { code: "TND", symbol: "د.ت" } },
    { TR: { code: "TRY", symbol: "₺" } },
    { TM: { code: "TMT", symbol: "T" } },
    { TC: { code: "USD", symbol: "$" } },
    { TV: { code: "AUD", symbol: "$" } },
    { UG: { code: "UGX", symbol: "USh" } },
    { UA: { code: "UAH", symbol: "₴" } },
    { AE: { code: "AED", symbol: "د.إ" } },
    { GB: { code: "GBP", symbol: "£" } },
    { US: { code: "USD", symbol: "$" } },
    { UM: { code: "USD", symbol: "$" } },
    { UY: { code: "UYU", symbol: "$U" } },
    { UZ: { code: "UZS", symbol: "лв" } },
    { VU: { code: "VUV", symbol: "VT" } },
    { VE: { code: "VES", symbol: "Bs." } },
    { VN: { code: "VND", symbol: "₫" } },
    { VG: { code: "USD", symbol: "$" } },
    { VI: { code: "USD", symbol: "$" } },
    { WF: { code: "XPF", symbol: "₣" } },
    { EH: { code: "MAD", symbol: "MAD" } },
    { YE: { code: "YER", symbol: "﷼" } },
    { ZM: { code: "ZMW", symbol: "ZK" } },
    { ZW: { code: "ZWL", symbol: "Z$" } },
];


export const getCurrencyInfo = (countryCode: string) => {
    const currencyInfo = currencySymbols.find((item: any) => countryCode in item);
    return currencyInfo ? currencyInfo[countryCode] : null;
}
