import { myFetch, ErrorHandler, HttpMethod } from "./baseService";

export interface Product {
  id: number;
  name: string;
  description: string;
  barcode: string;
  stock: number;
  stockMinimum: number;
  salePrice: number;
  saleLocalPrice: number;
  saleInternationalPrice: number;
  purchasePrice: number;
  publish: boolean;
  order: number;
  updateAt: string;
  createdAt: string;
  deletedAt: null;
  type: any,
  colors: any[],
  sizes: any[],
  imagePath: ImagePath[],
}
export interface ImagePath {
  id?: number
  imagenPath: string
  updateAt: string
  createdAt: string
  deletedAt: null
}
export type GeneralResponse = {
  message: string;
  data: Product[];
};

type GeneralResponseHandler = (data: GeneralResponse) => void;

export const productsPublished = async (
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/products/publish?page=1&limit=10&establishmentId=1",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );


export const searchProducts = async (
  search: string,
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/products/" + search + "?establishmentId=1",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.POST
  );



