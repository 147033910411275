import { useEffect, useState } from "react";
import { Config, configuration } from "../services/configService";
import { Section, sectionsTypesPublished } from "../services/sectionsService";
import HeaderLayout from "../components/layouts/HeaderLayout";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FullScreenLoader from "../components/utils/FullScreenLoader";
import { Client, login, register } from "../services/clientService";
import toast from "react-hot-toast";

const Register = () => {
  const [showForm, setShowForm] = useState(false)

  const formikRegister = useFormik<Client>({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Requerido'),
      email: Yup.string().required('Requerido'),
      password: Yup.string().required('Requerido'),
    }),
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      register(values, (data) => {
        toast.success(data.message)
        setShowForm(true)
        resetForm()
      }, (error) => toast.error(error))
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitting(false)
    },
  });

  const formikLogin = useFormik<Client>({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Requerido'),
      password: Yup.string().required('Requerido'),
    }),
    validateOnChange: false,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      login(values, (data) => {
        toast.success(data.message)
        resetForm()
      }, (error) => toast.error(error))
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubmitting(false)
    },
  });

  return (
    <>
      {formikRegister.isSubmitting && <FullScreenLoader />}
      <div className="mb-4 pb-4"></div>
      <section className="login-register container">
        <h2 className="d-none">Acceso & Registro</h2>
        <ul className="nav nav-tabs mb-5" id="login_register" role="tablist">
          <li className="nav-item" role="presentation">
            <a onClick={() => setShowForm(true)} className={`nav-link nav-link_underscore ${showForm ? "active" : ""}`} id="login-tab" data-bs-toggle="tab" href="#tab-item-login" role="tab" aria-controls="tab-item-login" aria-selected="true">Acceso</a>
          </li>
          <li className="nav-item" role="presentation">
            <a onClick={() => setShowForm(false)} className={`nav-link nav-link_underscore ${!showForm ? "active" : ""}`} id="register-tab" data-bs-toggle="tab" href="#tab-item-register" role="tab" aria-controls="tab-item-register" aria-selected="false">Registro</a>
          </li>
        </ul>
        <div className="tab-content pt-2" id="login_register_tab_content">
          <div className={`tab-pane fade ${showForm ? "show active" : ""}`} id="tab-item-login" role="tabpanel" aria-labelledby="login-tab">
            <div className="login-form">
            <form onSubmit={formikLogin.handleSubmit}>
                <div className="form-floating mb-3">
                  <input name="email" type="email" className="form-control form-control_gray" placeholder="Dirección de correo electrónico *" required
                    value={formikLogin.values.email}
                    onChange={formikLogin.handleChange}
                  />
                  <label htmlFor="customerEmailRegisterInput">Dirección de correo electrónico *</label>
                </div>

                <div className="pb-3"></div>

                <div className="form-floating mb-3">
                  <input name="password" type="password" className="form-control form-control_gray" placeholder="Contraseña *" required
                    value={formikLogin.values.password}
                    onChange={formikLogin.handleChange}
                  />
                  <label htmlFor="customerPasswodRegisterInput">Contraseña *</label>
                </div>

                <div className="d-flex align-items-center mb-3 pb-2">
                  <a href="./reset_password.html" className="btn-text ms-auto">¿Olvidaste tu contraseña?</a>
                </div>
                <button className="btn btn-primary w-100 text-uppercase" type="submit" disabled={formikLogin.isSubmitting}>Acceso</button>
                <div className="customer-option mt-4 text-center">
                  <span className="text-secondary">¿No tienes cuenta aún?</span>
                  <div style={{ cursor: 'pointer' }} onClick={() => setShowForm(false)} className="btn-text js-show-register">Crear una cuenta</div>
                </div>
              </form>
            </div>
          </div>
          <div className={`tab-pane fade ${!showForm ? "show active" : ""}`} id="tab-item-register" role="tabpanel" aria-labelledby="register-tab">
            <div className="register-form">
              <form onSubmit={formikRegister.handleSubmit}>
                <div className="form-floating mb-3">
                  <input name="firstName" type="text" className="form-control form-control_gray" placeholder="Nombres" required
                    value={formikRegister.values.firstName}
                    onChange={formikRegister.handleChange}
                  />
                  <label htmlFor="customerNameRegisterInput">Nombres</label>
                </div>
                <div className="pb-3"></div>
                <div className="form-floating mb-3">
                  <input name="lastName" type="text" className="form-control form-control_gray" placeholder="Apellidos"
                    value={formikRegister.values.lastName}
                    onChange={formikRegister.handleChange}
                  />
                  <label htmlFor="customerNameRegisterInput">Apellidos</label>
                </div>

                <div className="pb-3"></div>

                <div className="form-floating mb-3">
                  <input name="email" type="email" className="form-control form-control_gray" placeholder="Dirección de correo electrónico *" required
                    value={formikRegister.values.email}
                    onChange={formikRegister.handleChange}
                  />
                  <label htmlFor="customerEmailRegisterInput">Dirección de correo electrónico *</label>
                </div>

                <div className="pb-3"></div>

                <div className="form-floating mb-3">
                  <input name="password" type="password" className="form-control form-control_gray" placeholder="Contraseña *" required
                    value={formikRegister.values.password}
                    onChange={formikRegister.handleChange}
                  />
                  <label htmlFor="customerPasswodRegisterInput">Contraseña *</label>
                </div>

                <div className="d-flex align-items-center mb-3 pb-2">
                  <p className="m-0">Sus datos personales se utilizarán para respaldar su experiencia en este sitio web, para administrar el acceso a su cuenta y para otros fines descritos en nuestra política de privacidad.</p>
                </div>

                <button className="btn btn-primary w-100 text-uppercase" type="submit" disabled={formikRegister.isSubmitting}>Registro</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
