import { GeneralItem } from "../../services/configService"


interface NewsletterPoupPropsType {
    configGeneral?: GeneralItem
}
const NewsletterPoup: React.FC<NewsletterPoupPropsType> = ({ configGeneral }) => {
    return (
        <>
            <div className="modal fade" id="newsletterPopup" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog newsletter-popup modal-dialog-centered">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="row p-0 m-0">
                            <div className="col-md-6 p-0 d-none d-md-block">
                                <div className="newsletter-popup__bg h-100 w-100">
                                    <img loading="lazy"
                                        src={configGeneral && process.env.REACT_APP_API_URL + configGeneral?.data.modalPath}
                                        className="h-100 w-100 object-fit-cover d-block"
                                        alt="" />
                                </div>
                            </div>
                            <div className="col-md-6 p-0 d-flex align-items-center">
                                <div className="block-newsletter w-100">
                                    <h3 className="block__title">Suscríbase a nuestro boletín de noticias</h3>
                                    <p>¡Sé el primero en recibir las últimas noticias sobre tendencias, promociones y mucho más!</p>
                                    <form action="./index.html" className="footer-newsletter__form position-relative bg-body">
                                        <input className="form-control border-2" type="email" name="email" placeholder="Su dirección de correo electrónico" />
                                        <input className="btn-link fw-medium bg-transparent position-absolute top-0 end-0 h-100" type="submit"
                                            value="UNIRSE" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default NewsletterPoup
