import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken } from '../services/clientService';

function AuthRedirect() {
    const location = useLocation();
    const navigate = useNavigate()
    useEffect(() => {

        const initial = async () => {
            const queryParams = new URLSearchParams(location.search);
            const code = queryParams.get('code');
            try {
                getToken({ code }, (response: any) => {
                }, error => console.error(error))

            } catch (error) {
                console.error('Error al obtener el token:', error);
            }
        }
        navigate('/');
        return () => {
            initial()
        }
    }, [navigate]);


    return <div></div>;
}
export default AuthRedirect