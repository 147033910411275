//TODO: Si es un service porque esta dentro de utils? Tiene su carpeta de services.

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

export type ErrorHandler = (error: string) => void;
export type FetchResponse = {
  message: string;
  data: any;
  statusCode?: number
};

export async function myFetch<T extends FetchResponse>(
  endpoint: string,
  pay: string,
  resultHandler: (response: T) => void,
  errorHandler: ErrorHandler,
  method = HttpMethod.POST,
  aditionalHeaders?: { [key: string]: string }
) {
  const parse = pay !== "" ? JSON.parse(pay) : pay;
  const payload = { ...parse };
  const url = process.env.REACT_APP_API_URL + endpoint;
  const body =
    method !== HttpMethod.GET ? { body: JSON.stringify(payload) } : {};

  const request = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...aditionalHeaders,
    },
    ...body,
  };

  return fetch(url, request)
    .then(async (response) => {
      return response.json();
    })
    .then((data: T) => {
      if (data.statusCode && data.statusCode >= 400) {
        throw new Error(data.message);
      }
      resultHandler(data);
    })
    .catch((error: Error) => {
      errorHandler(error.message);
    });
}
