import React from "react";

const Contact = () => {
  return (
    <div className="container text-primaryColor w-full py-10">
      <h2 className="text-primaryColor text-3xl">Estamos para ayudarte</h2>
      <p className="text-base mt-2">
        Para cualquier duda o asistencia Post Venta, no dude en escribirnos.
      </p>
      <div className="flex flex-col md:flex-row w-full">
        <div className="flex md:w-8/12">
          <form action="" className="mt-8 w-full">
            <div className="grid grid-cols-2 gap-4 w-full">
              <div>
                <input
                  className="border-primaryColor border-2 footer-placeholder w-full pl-4 py-2"
                  type="text"
                  placeholder="Nombre"
                />
              </div>
              <div>
                <input
                  className="border-primaryColor border-2 footer-placeholder w-full pl-4 py-2"
                  type="text"
                  placeholder="Correo Electrónico"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 mt-4 gap-4">
              <div>
                <input
                  className="border-primaryColor border-2 footer-placeholder w-full pl-4 py-2"
                  type="text"
                  placeholder="Número de Teléfono"
                />
              </div>
              <div></div>
            </div>
            <div className="mt-4">
              <textarea
                className="border-primaryColor border-2 footer-placeholder w-full pl-4 py-2 h-72"
                name=""
                id=""
                placeholder="Mensaje"
                rows={10}
              ></textarea>
            </div>
            <div className="flex flex-row">
              <input id="" type="checkbox" value="" className="text-center ml-4 md:ml-0" />
              <p className="text-base ml-4">
                Guardar mi nombre, correo electrónico y sitio web en este
                navegador para la próxima vez que comente.
              </p>
            </div>
                <div className="flex justify-center md:block">
                <button className="uppercase bg-backgroundColor px-10 py-2 mt-8">
              Enviar Ahora
            </button>
                </div>
           
          </form>
        </div>
        <div className="uppercase mt-8 md:mt-0 md:ml-10 flex-1  md:w-1/2">
          <h2 className="flex justify-center md:block text-primaryColor text-2xl">
            Atención post venta
          </h2>
          <p className="flex justify-center md:block mt-10">(+51) 977528365</p>
          <p className="flex justify-center md:block mt-2">
            informes@ilaini.com
          </p>
          <p className="flex justify-center md:block uppercase mt-4 text-lg">
            Síguenos
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
