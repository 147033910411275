import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Register from './pages/Register';
import Layout from './components/layouts/Layout';
import { Toaster } from 'react-hot-toast'
import Culqii from './pages/Culqii';
import AuthRedirect from './pages/AuthRedirect';
import Section from './pages/Section';
import SectionProducts from './pages/Section';
import TypeProducts from './pages/Type';
import SubTypeProducts from './pages/SubType';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import { useEffect, useState } from 'react';
import { getCountryFromLocation } from './components/utils/Geolocation';
import { Establishment, getEstablishments } from './services/establishmentService';
import { getCurrencyInfo } from './components/utils/CountryCurrency';
import FindUs from './pages/us/FindUs';
import { useCurrencySweet } from './services/contexts/useCurrencySweet';
import FullScreenLoader from './components/utils/FullScreenLoader';
import { useRequestContext } from './services/contexts/useContextSweet';
import './fonts/fonts.css'
import './App.css';
import OurHistory from './pages/us/OurHistory';
import PrivacyPolicy from './pages/us/PrivacyPolicy';
import TermsUse from './pages/us/TermsUse';
import CorporateSales from './pages/us/CorporateSales';
import Contact from './pages/customerService/Contact';
import Shipping from './pages/customerService/Shipping';
import ReturnsExchanges from './pages/customerService/ReturnsExchanges';
import SizeGuide from './pages/customerService/SizeGuide';
import ClothingCare from './pages/customerService/ClothingCare';

function App() {
  const [
    { }, { setCurrencyInformation }] = useCurrencySweet();
  const [{ }, { loadingHandler }] = useRequestContext();


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/register",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Register />,
        }
      ]
    },
    {
      path: "/section/:id",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <SectionProducts />,
        }
      ]
    },
    {
      path: "/type/:id",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <TypeProducts />,
        }
      ]
    },
    {
      path: "/sub-type/:id",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <SubTypeProducts />,
        }
      ]
    },
    {
      path: "/blog",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Blog />,
        }
      ]
    },
    {
      path: "/blog/:id",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <BlogDetails />,
        }
      ]
    },
    {
      path: "/auth-redirect",
      element: <AuthRedirect />,
    },
    {
      path: "/contact",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <FindUs />,
        }
      ]
    },
    {
      path: "/find-us",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <FindUs />,
        }
      ]
    },
    {
      path: "/our-history",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <OurHistory />,
        }
      ]
    },
    {
      path: "/sustainability",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <OurHistory />,
        }
      ]
    },
    {
      path: "/privacy-policy",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <PrivacyPolicy />,
        }
      ]
    },
    {
      path: "/terms-of-use",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <TermsUse />,
        }
      ]
    },
    {
      path: "/corporate-sales",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <CorporateSales />,
        }
      ]
    },
    {
      path: "/contact-us",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Contact />,
        }
      ]
    },
    {
      path: "/shipments",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Shipping />,
        }
      ]
    },
    {
      path: "/returns",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <ReturnsExchanges />,
        }
      ]
    },
    {
      path: "/size-guide",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <SizeGuide />,
        }
      ]
    },
    {
      path: "/garment-care",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <ClothingCare />,
        }
      ]
    },
  ]);
  useEffect(() => {

    const fetchGeolocation = async () => {
      try {
        const location = await getCountryFromLocation()
        console.warn(location)
        console.log(`Tu ubicación es \n País: ${location.country_name}\n Capital: ${location.country_capital}`);
        const establishmentList = await getEstablishments()

        establishmentList[0].latitud = -12.046374
        establishmentList[0].longitud = -77.042793

        establishmentList[1].latitud = -40.900557
        establishmentList[1].longitud = 174.885971


        const exist = establishmentList.some(establishment => establishment.country.id === location.country_code);
        if (exist) {
          const currencyInfo = getCurrencyInfo(location.country_code);
          if (currencyInfo) {
            const { code, symbol } = currencyInfo;
            localStorage.setItem('currencyInfo', JSON.stringify({ ...currencyInfo, country_name: location.country_name }))
            console.log(`Para ${location.country_code} la moneda es ${code} y su símbolo es ${symbol}`);
            setCurrencyInformation({ ...location, code, symbol, local: true })
          } else {
            console.log(`No se encontró información para el código de país`);
          }
        } else {
          localStorage.setItem('currencyInfo', JSON.stringify({ code: 'USD', symbol: "$", country_name: location.country_name }))
          setCurrencyInformation({ ...location, code: 'USD', symbol: "$", local: false })
          console.log(`Ahora no hay almacén para el país ${location.country_name}, tu moneda es USD y su símbolo es $ `);
        }

        let distanciaMinima = Number.MAX_VALUE;
        let tiendaMasCercana: any = null;


        establishmentList.forEach((tienda) => {
          const distancia = calculateDistance(
            location.latitude,
            location.longitude,
            tienda.latitud,
            tienda.longitud
          );
          if (distancia < distanciaMinima) {
            distanciaMinima = distancia;
            tiendaMasCercana = tienda;
          }
        });
        console.warn("Almacén más cercano: " + tiendaMasCercana?.description)
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    }

    return () => {
      loadingHandler(true)
      fetchGeolocation();
      loadingHandler(false)
    }
  }, []);
  const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    const radioTierra = 6371; // Radio de la Tierra en kilómetros
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distancia = radioTierra * c;
    return distancia;
  };
  const deg2rad = (deg: any) => {
    return deg * (Math.PI / 180);
  };
  return (
    <>
      <RouterProvider router={router} />
      <Toaster position={'top-center'} toastOptions={{ className: 'react-hot-toast' }} />
    </>

  );

}

export default App;
