import React from "react";
import Banner from "../../components/common/Banner";
import imageSize from "../../assets/images/size_guide.jpg";

const SizeGuide = () => {
  return (
    <>
      <Banner banner={imageSize} text={"Guía de Tallas"} />
      <div className="container">
        <div className="py-10 space-y-4">
          <div className="border-t-2 border-primaryColor" />
          <div className="mb-4 flex items-center justify-between">
            <h2 className="uppercase text-primaryColor font-bold text-xl">
              Mujer
            </h2>
            <button className="text-2xl">+</button>
          </div>
          <div className="border-t border-primaryColor" />

          <div className="mb-4 flex items-center justify-between">
            <h2 className="uppercase text-primaryColor font-bold text-xl">
              Hombre
            </h2>
            <button className="text-2xl">+</button>
          </div>
          <div className="border-t border-primaryColor" />
          <div className="mb-4 flex items-center justify-between">
            <h2 className="uppercase text-primaryColor font-bold text-xl">
              Niños
            </h2>
            <button className="text-2xl">+</button>
          </div>
          <div className="border-t border-primaryColor" />
        </div>
      </div>
    </>
  );
};

export default SizeGuide;
