import { useRequestContext } from '../../services/contexts/useContextSweet';
import './Loading.css'; // Import your CSS file

const FullScreenLoader = () => {

	const [{ isLoading }, { }] = useRequestContext();
	if (!isLoading) {
		return null;
	}
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: "#ffffff",
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 1000,
			}}>
			<div className="spinner"></div>
		</div>
	);
};

export default FullScreenLoader;
