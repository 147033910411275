import React from "react";
import { Link } from "react-router-dom";

const Shipping = () => {
  return (
    <div className="container text-primaryColor">
      <h2 className="flex justify-center text-3xl text-primaryColor">Envíos</h2>
      <div className="grid justify-center md:grid-cols-2 x1280:grid-cols-3  gap-4 text-center mt-10">
        <div className="flex justify-center">
        <div className="lg:w-96  w-80">
            <div className="border-primaryColor border-2 h-[350px] flex flex-col justify-between p-4">
              <h2>Arequipa</h2>
              <div className="mt-auto">
                <Link to="/" className="px-8 py-2 bg-backgroundColor">
                  Conoce Más
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="lg:w-96  w-80">
            <div className="border-primaryColor border-2 h-[350px] flex flex-col justify-between p-4">
              <h2>Nacional</h2>
              <div className="mt-auto">
                <Link to="/" className="px-8 py-2 bg-backgroundColor">
                  Conoce Más
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:col-span-2 x1280:col-span-1">
          <div className="lg:w-96  w-80">
            <div className="border-primaryColor border-2 h-[350px] flex flex-col justify-between p-4">
              <h2>Internacional</h2>
              <div className="mt-auto">
                <Link to="/" className="px-8 py-2 bg-backgroundColor">
                  Conoce Más
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 space-y-4">
        <div className="border-t-2 border-primaryColor" />
        <div className="mb-4 flex items-center justify-between">
          <h2 className="uppercase text-primaryColor font-bold text-xl">
            Arequipa
          </h2>
          <button className="text-2xl">+</button>
        </div>
        <div className="border-t border-primaryColor" />

        <div className="mb-4 flex items-center justify-between">
          <h2 className="uppercase text-primaryColor font-bold text-xl">
            Nacional
          </h2>
          <button className="text-2xl">+</button>
        </div>
        <div className="border-t border-primaryColor" />

        <div className="mb-4 flex items-center justify-between">
          <h2 className="uppercase text-primaryColor font-bold text-xl">
            Internacional
          </h2>
          <button className="text-2xl">+</button>
        </div>
        <div className="border-t border-primaryColor" />
      </div>
    </div>
  );
};

export default Shipping;
