import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
export type Blog = {
  id?: number
  title: string
  shortDescription: string
  description: string
  imagePath: string
  updateAt?: string
  createdAt?: string
  deletedAt?: null
}


export type GeneralResponse = {
  message: string;
  data: Blog[] | Blog;
};

type GeneralResponseHandler = (data: GeneralResponse) => void;

export const getBlogs = async (
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/blog",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );


export const getBlog = async (id: number, resultHandler: GeneralResponseHandler, errorHandler: ErrorHandler) =>
  myFetch<GeneralResponse>(`shoppings/blog/${id}`, '', resultHandler, errorHandler, HttpMethod.GET)
