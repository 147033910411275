import { useEffect, useState } from "react";
import { Config, configuration } from "../services/configService";
import { Section, getProductsSectionsById, getSection, sectionsTypesPublished } from "../services/sectionsService";
import HeaderLayout from "../components/layouts/HeaderLayout";
import { useFormik } from "formik";
import * as Yup from 'yup';
import FullScreenLoader from "../components/utils/FullScreenLoader";
import { Client, login, register } from "../services/clientService";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Product } from "../services/productsService";
import { Type, getType } from "../services/typeService";
import { SubType } from "../services/subTypeService";
import { Blog, getBlogs } from "../services/blogsService";
import moment from "moment";

const BlogScreen = () => {
  const [showForm, setShowForm] = useState(false)
  const [type, setType] = useState<Type>()
  const [blogsList, setBlogsList] = useState<Blog[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setLoading(true)
      getBlogs((response) => setBlogsList(response.data as Blog[]), error => console.error(error))
      setLoading(false)
    }
  }, [])

  return (
    <>
      <section className="blog-page-title mb-4 mb-xl-5">
        <div className="title-bg">
          <img loading="lazy" src={require('../assets/images/blog_title_bg.jpg')} width="1780" height="420" alt="" />
        </div>
        <div className="container">
          <h2 className="page-title">The Blog</h2>
        </div>
      </section>
      <section className="blog-page container">
        <h2 className="d-none">The Blog</h2>
        <div className="blog-grid row row-cols-1 row-cols-md-2 row-cols-xl-3">
          {blogsList.map(blog => (
            <div key={blog.id} className="blog-grid__item">
              <div className="blog-grid__item-image">
                <img loading="lazy" className="h-auto"
                  src={process.env.REACT_APP_API_URL + (blog.imagePath.length && blog.imagePath || "")}

                  width="450" height="400" alt="" />
              </div>
              <div className="blog-grid__item-detail">
                <div className="blog-grid__item-meta">
                  <span className="blog-grid__item-meta__author">Por administrador</span>
                  <span className="blog-grid__item-meta__date">{moment(blog.createdAt).format("MMMM DD, YYYY")}</span>
                </div>
                <div className="blog-grid__item-title">
                  <a href="./blog_single.html">{blog.title}</a>
                </div>
                <div className="blog-grid__item-content">
                  <p>{blog.shortDescription}</p>
                  <a href={"/blog/" + blog.id} className="readmore-link">Seguir Leyendo</a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="mb-1 text-center fw-medium">SHOWING 36 of 497 items</p>
        <div className="progress progress_uomo mb-3 ms-auto me-auto" style={{ width: 300 }}>
          <div className="progress-bar" role="progressbar" style={{ width: "39%" }}>

          </div>
        </div>

        <div className="text-center">
          <a className="btn-link btn-link_lg text-uppercase fw-medium" href="#">Show More</a>
        </div>
      </section >
    </>
  );
};

export default BlogScreen;
