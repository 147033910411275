import React from "react";
import { Link } from "react-router-dom";
import imageWorkshopReturn from "../../assets/images/workshop_returns.jpg";
import imageCourierReturn from "../../assets/images/courier_Returns.jpg";

const ReturnsExchanges = () => {
  return (
    <div className="container text-primaryColor py-10">
      <h2 className="flex justify-center text-primaryColor text-4xl font-eur42" style={{ letterSpacing: 3 }}>
        Cambios y Devoluciones
      </h2>
      <div className="grid justify-center md:grid-cols-2 x1280:grid-cols-3  gap-4 text-center mt-10">
        <div className="flex justify-center">
          <div className="lg:w-96  w-80">
            <div className="border-primaryColor border-2 h-[350px] flex flex-col justify-between p-4">
              <h2 className="text-primaryColor">Politica de Devolución</h2>
              <div className="flex justify-center mt-12">
                <div className="flex justify-center w-36 h-36 border-2 border-primaryColor rounded-full">
                  <p className="uppercase flex items-center justify-center text-lg">
                    30 Días
                  </p>
                </div>
              </div>

              <div className="mt-auto">
                <Link to="/" className="px-8 py-2 bg-backgroundColor">
                  Conoce Más
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="lg:w-96  w-80">
            <div className="border-primaryColor border-2 h-[350px] flex flex-col justify-between p-4">
              <h2 className="text-primaryColor">Recojo en taller</h2>
              <div className="flex justify-center">
                <img className="w-64" src={imageWorkshopReturn} alt="" />
              </div>

              <div className="mt-auto">
                <Link to="/" className="px-8 py-2 bg-backgroundColor">
                  Conoce Más
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:col-span-2 x1280:col-span-1">
          <div className="lg:w-96  w-80">
            <div className="border-primaryColor border-2 h-[350px] flex flex-col justify-between p-4">
              <h2 className="text-primaryColor">Retorno via courier</h2>
              <div className="flex justify-center">
                <img className="w-64" src={imageCourierReturn} alt="" />
              </div>
              <div className="mt-auto">
                <Link to="/" className="px-8 py-2 bg-backgroundColor">
                  Conoce Más
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <h2 className="uppercase text-primaryColor text-lg">
          Politica y devolución de Ilaini
        </h2>
        <div className="ml-4 mt-8">
          <p>
            In addition to your Statutory Rights, the Seed Heritage Returns
            Policy sets out how you can return your purchase from a Seed
            Heritage Concept Store, Seed Heritage Outlet Store or Seed Heritage
            Online (excluding Seed Heritage Concession Stores in department
            stores) because you:
          </p>
        </div>
        <div className="grid md:grid-cols-3 text-center mt-8 gap-4">
          <div className="flex justify-center flex-col">
            <div className="flex justify-center mt-8">
              <div className="flex justify-center w-36 h-36 border-2 border-primaryColor rounded-full">
                <p className="uppercase flex items-center justify-center text-lg">
                  30 Días
                </p>
              </div>
            </div>
            <p className="mt-4">
              The product is returned within the following change of mind
              timeframes
            </p>
          </div>
          <div className="flex justify-center flex-col">
            <div className="flex justify-center mt-8">
              <div className="flex justify-center w-36 h-36 border-2 border-primaryColor rounded-full">
                <p className="uppercase flex items-center justify-center text-lg">
                  30 Días
                </p>
              </div>
            </div>
            <p className="mt-4">
              The product is returned within the following change of mind
              timeframes
            </p>
          </div>
          <div className="flex justify-center flex-col">
            <div className="flex justify-center mt-8">
              <div className="flex justify-center w-36 h-36 border-2 border-primaryColor rounded-full">
                <p className="uppercase flex items-center justify-center text-lg">
                  30 Días
                </p>
              </div>
            </div>
            <p className="mt-4">
              The product is returned within the following change of mind
              timeframes
            </p>
          </div>
        </div>
      </div>

      <div className="py-10 space-y-4">
        <div className="border-t-2 border-primaryColor" />
        <div className="mb-4 flex items-center justify-between">
          <h2 className="uppercase text-primaryColor font-bold text-xl">
            Terminos y condiciones
          </h2>
          <button className="text-2xl">+</button>
        </div>
        <div className="border-t border-primaryColor" />

        <div className="mb-4 flex items-center justify-between">
          <h2 className="uppercase text-primaryColor font-bold text-xl">
            Sale y outlet
          </h2>
          <button className="text-2xl">+</button>
        </div>
        <div className="border-t border-primaryColor" />
      </div>
    </div>
  );
};

export default ReturnsExchanges;
