import { useEffect, useState } from "react";
import { Section } from "../../services/sectionsService";
import { GeneralItem } from "../../services/configService";
import CustomerLoginForm from "./CustomerLoginForm";
import useDebounce from "../../hooks/useDebounce";
import { Product, searchProducts } from "../../services/productsService";

interface HeaderPropsType {
  sections: Section[]
  logo: string
  configGeneral?: GeneralItem
}

const HeaderWeb: React.FC<HeaderPropsType> = ({ sections, logo, configGeneral }) => {
  const [showAside, setShowAside] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 500);
  const [products, setProducts] = useState<Product[]>([])
  const [isSticky, setIsSticky] = useState(false);
  const [colorSection, setColorSection] = useState("#EFE2CF");
  const [isScrolll, setIsScroll] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsSticky(scrollTop > 50);
    if (scrollTop > 50) {
      setColorSection("#743F05")
      setIsScroll(true)
    } else {
      setColorSection("#EFE2CF")
      setIsScroll(false)

    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const fetchProducts = async () => {
    if (!debouncedQuery) return;
    try {
      searchProducts(debouncedQuery, ({ data }) =>
        setProducts(data)
        , error =>
          console.error(error)
      )
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [debouncedQuery]);

  return (
    <>
      <header
        id="header"
        className="header header-fullwidth header-transparent-bg"
        style={{
          position: 'fixed',
          backgroundColor: isSticky ? 'white' : 'transparent',
          transition: 'background-color 0.3s',
          zIndex: 1000,
        }}
      >
        {configGeneral?.data.publishSection &&
          <div className="header-top d-flex bg-black color-white align-items-center">
            <ul className="list-unstyled d-flex flex-1 gap-3 m-0">
            </ul>
            <p className="mx-auto mb-0">{configGeneral?.data.section}</p>
            <div className="heeader-top__right flex-1 d-flex gap-1 justify-content-end">

            </div>
          </div>
        }
        <div className="header-desk header-desk_type_2">
          <nav className="navigation d-flex">
            <ul className="navigation__list list-unstyled d-flex">
              {sections.map((section) => (
                <li key={section.id} className="navigation__item">
                  <a href={"/section/" + section.id} className="navigation__link" style={{ fontWeight: 400, fontFamily: 'EUR42', color: colorSection }}>
                    {section.name}
                  </a>
                  <div className="box-menu" style={{ width: 800 }}>
                    <div className="col pe-4">
                      <ul className="sub-menu__list list-unstyled">
                        {section.type.map((type) => (
                          <li key={type.id} className="sub-menu__item">
                            <a href={"/type/" + type.id} className="menu-link menu-link_us-s" >
                              {type.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
              <li className="navigation__item">
                <a href={"/"} className="navigation__link" style={{ fontWeight: 400, fontFamily: 'EUR42', color: colorSection }}>
                  Sale
                </a>
              </li>
              {/* <li className="navigation__item">
                <a href={"/blog"} className="navigation__link" style={{ fontWeight: 400, fontFamily: 'EUR42', color: colorSection }}>
                  Blog
                </a>
              </li>
              <li className="navigation__item">
                <a href={"/contact"} className="navigation__link" style={{ fontWeight: 400, fontFamily: 'EUR42', color: colorSection }}>
                  Contacto
                </a>
              </li> */}
            </ul>
          </nav>

          <div className="logo">
            <a href="/">
              <img
                src={!isScrolll ? process.env.REACT_APP_API_URL + logo : require("../../assets/images/Ilaini.png")}
                alt="Ilaini"
                className="logo__image d-block"
              />
            </a>
          </div>

          <div className="header-tools d-flex align-items-center">
            <div className={`header-tools__item hover-container ${showSearch ? "js-content_visible" : ""} `}>
              <div className="js-hover__open position-relative">
                <a className="js-search-popup search-field__actor" href="#" onClick={() => setShowSearch(!showSearch)}>
                  <svg
                    className="d-block"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color={colorSection}
                  >
                    <use href="#icon_search" />
                  </svg>
                  <i className="btn-icon btn-close-lg" style={{ color: colorSection }}></i>
                </a>
              </div>

              <div className="search-popup js-hidden-content">
                <form
                  className={`search-field container ${products.length ? "search-field__focused" : ""}`}
                  onSubmit={(event) => event.preventDefault()}
                >
                  <p className="text-uppercase text-secondary fw-medium mb-4">
                    ¿QUÉ ESTÁS BUSCANDO?
                  </p>
                  <div className="position-relative">
                    <input
                      className="search-field__input search-popup__input w-100 fw-medium"
                      type="text"
                      placeholder="Buscar Prendas"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <button
                      className="btn-icon search-popup__submit"
                      type="submit"
                    >

                    </button>
                    <button
                      className="btn-icon btn-close-lg search-popup__reset"
                      type="reset"
                    ></button>
                  </div>

                  <div className="search-popup__results">
                    <div className="search-result row row-cols-5">
                      {products.map((product, index) => (
                        <div key={index} className="product-card">
                          <div className="pc__img-wrapper position-relative">
                            <img loading="lazy" className="pc__img" alt=""
                              src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[0].imagenPath || "")}
                            />
                            <img loading="lazy"
                              src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[1].imagenPath || "")}
                              alt={product?.name} className="pc__img pc__img-second" />
                          </div>
                          <div className="pc__info">
                            <p className="pc__category">{product?.type?.name}</p>
                            <h6 className="pc__title">{product?.name}</h6>
                            <div className="product-card__price d-flex">
                              <span className="money price">{product?.salePrice}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="header-tools__item hover-container">
              <a className="js-open-aside" href="#" data-aside="customerForms" onClick={() => setShowAside(true)}>
                <svg
                  className="d-block"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  color={colorSection}
                >
                  <use href="#icon_user" />
                </svg>
              </a>
            </div>
            <a
              // href="#"
              className="header-tools__item header-tools__cart "
            // js-open-aside
            // data-aside="cartDrawer"
            >
              <svg
                className="d-block"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                color={colorSection}
              >
                <use href="#icon_cart" />
              </svg>
              <span className="cart-amount d-block position-absolute js-cart-items-count">
                3
              </span>
            </a>
          </div>
        </div>
      </header >
      <CustomerLoginForm showAside={showAside} setShowAside={setShowAside} />
    </>
  );
};
export default HeaderWeb;
