import React from "react";
import imageClothingCare from "../../assets/images/clothing_care.jpg";
import imageClothingCare1 from "../../assets/images/image_clothing_care_1.jpg";
import imageClothingCare2 from "../../assets/images/image_clothing_care_2.jpg";
import Banner from "../../components/common/Banner";
const ClothingCare = () => {
  return (
    <>
      <Banner banner={imageClothingCare} text={"Cuidado de prendas"} />
      <div className="container text-primaryColor py-10">
        <p>
          Nuestras prendas de punto están confeccionadas con tejidos naturales
          de primera calidad, hechas a mano con pura Baby Alpaca. Cada pieza es
          única, y algunas mezclas contienen merino y poliamida para aumentar la
          durabilidad, por lo que requerirán cuidados específicos. Estas prendas
          son acogedoras, cómodas y constituyen un elemento clave en el estilo
          para climas fríos.
        </p>
        <div className="">
          <h2 className="uppercase mt-8 text-2xl text-primaryColor">
            Recomendable
          </h2>
          <div className="space-y-4 ml-4 mt-4">
            <p>
              · Lavar a mano en agua fría, evitar frotar demasiado fuerte o
              torcerlas para mantener su forma y durabilidad.
            </p>
            <p>
              · Planchar a vapor, ya humedece a las fibras de los tejidos, lo
              que facilita el planchado y aporta elasticidad a las prendas.
            </p>
            <p>
              · Lavar en seco, elimina manchas y suciedad de la ropa utilizando
              productos especiales sin agua. Es especialmente adecuado para
              prendas delicadas y materiales como gamuza, cuero, piel, seda y
              lana.
            </p>
            <p>
              · Secar en plano, extendiéndolas sobre una superficie plana, como
              una toalla o una rejilla, en lugar de colgarlas. Esto ayuda a
              mantener la forma original de las prendas y evita que se estiren o
              deformen.
            </p>
          </div>
        </div>

        <div className="">
          <h2 className="uppercase mt-8 text-2xl text-primaryColor">
            No Recomendable
          </h2>
          <div className="space-y-4 ml-4 mt-4">
            <p>
              · No usar blanqueadores, porque pueden debilitar las fibras de las
              prendas, lo que afecta su durabilidad y apariencia. provocan
              decoloración en los tejidos, especialmente en prendas de colores
              vivos. Y además, contienen sustancias perjudiciales para el medio
              ambiente.
            </p>
            <p>
              · No escurrir, evitar torcer o retorcer las prendas después de
              lavarlas. En lugar de eso, presiona suavemente el exceso de agua
              con una toalla. El escurrido excesivo puede dañar las fibras y
              afectar la durabilidad de las telas.
            </p>
            <p>
              · No planchar las prendas, después de lavar tus prendas, cuélgalas
              al aire libre en una percha o tendedero.
            </p>
          </div>

          <p className="mt-8 italic">
            Recuerda: que la Fibra de Baby Alpaca es natural, elegante y
            resistente; con estos cuidados, podrás lucir tus prendas en buen
            estado.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center text-center mt-8">
          <img className="w-96 " src={imageClothingCare1} alt="" />
          <img className="w-72 mt-10" src={imageClothingCare2} alt="" />
        </div>
      </div>
    </>
  );
};

export default ClothingCare;
