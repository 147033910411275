import { createStore, createHook, StoreActionApi } from 'react-sweet-state';

type State = {
    isLoading: boolean;
    error: string;
    textLoading?: string;
};
type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const initialState: State = {
    isLoading: false,
    textLoading: '',
    error: ''
};

const actions = {
    loadingHandler:
        (isLoading: boolean, textLoading?: string) =>
            ({ setState }: StoreApi) => {
                setState({
                    isLoading: isLoading,
                    textLoading: textLoading
                });
            },
};
const Store = createStore<State, Actions>({
    initialState,
    actions
});

export const useRequestContext = createHook(Store);
