import { myFetch, ErrorHandler, HttpMethod } from "./baseService";


interface GeneralResponse {
  message: string;
  data: Config;
}

export interface Config {
  general: GeneralItem;
  socials: SocialItem[];
  banners: BannerItem[];
}

export interface GeneralItem {
  data: {
    id: number;
    logoPath: string;
    modalPath: string;
    section: string;
    email: string;
    number: number;
    telephone: number;
    address: string;
    publishModal: boolean;
    publishSection: boolean;
    updateAt: string;
    createdAt: string;
    deletedAt: null | string;
  }

}

export interface SocialItem {
  id: number;
  name: string;
  url: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null | string;
}

export interface BannerItem {
  id: number;
  title: string;
  description: string;
  offer: string
  imagePath: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null | string;
}
type GeneralResponseHandler = (data: GeneralResponse) => void;

export const configuration = async (
  resultHandler: GeneralResponseHandler,
  errorHandler: ErrorHandler
) =>
  myFetch<GeneralResponse>(
    "shoppings/configuration",
    "",
    resultHandler,
    errorHandler,
    HttpMethod.GET
  );

// export const configuration = (
// ) => {
//   return new Promise<GeneralResponse>((resolve, reject) => {
//     myFetch<GeneralResponse>(
//       "shoppings/configuration",
//       "",
//       (response) => {
//         resolve(response);
//       },
//       (error) => {
//         reject(error);
//       },
//       HttpMethod.GET
//     );
//   });
// };
