import { myFetch, ErrorHandler, HttpMethod } from "./baseService";
import { SubType } from "./subTypeService";

export interface Type {
  id: number;
  name: string;
  publish: boolean;
  updateAt: string;
  createdAt: string;
  deletedAt: null | string;
  subType: SubType[]
}

export type GeneralResponse = {
  message: string;
  data: Type[] | Type;
};

type GeneralResponseHandler = (data: GeneralResponse) => void;


export const getType = async (id: number, resultHandler: GeneralResponseHandler, errorHandler: ErrorHandler) =>
  myFetch<GeneralResponse>(`admin/types/${id}`, '', resultHandler, errorHandler, HttpMethod.GET)
