import React from 'react'

const TermsUse = () => {
  return (
    <>
      <hr />
      <div className="container text-primaryColor space-y-8 mt-5">
        <div className="container text-primaryColor space-y-8">
          <h2 className="flex justify-center text-primaryColor text-4xl font-eur42" style={{ letterSpacing: 3 }}>
            Términos de Uso
          </h2>
          <p className="mt-8">(CURRENT AS OF 22 DECEMBER 201 )</p>
          <p>
            At Steambrook Pty Ltd ABN 63 114 765 703 ("Seed Heritage", "we", "us")
            your privacy is important to us. We are committed to protecting your
            privacy when managing your personal information. We have policies and
            procedures to ensure that all personal information is handled carefully
            and securely in accordance with the Australian Privacy Principles (APPs)
            contained in the Privacy Act 1988 (Cth) ("Privacy Act").
          </p>

        </div>
      </div>

    </>
  )
}

export default TermsUse