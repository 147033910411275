import { useEffect, useState } from "react";
import { Product, productsPublished } from "../../services/productsService";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import QuickView from "./QuickView";
import { useCurrencySweet } from "../../services/contexts/useCurrencySweet";
import Skeleton from "react-loading-skeleton";
import useWindowSize from "../hooks/useWindowSize";
const NaturalBalance = () => {

    const [products, setProducts] = useState<Product[]>([]);
    const [productData, setProductData] = useState<Product>();

    useEffect(() => {
        const initialProducts = () => {
            productsPublished(
                ({ data }) => setProducts(data),
                (err) => console.error(err)
            );
        }
        initialProducts()
        return () => {
            initialProducts()
        };
    }, []);
    return (
        <section id="carousel-products" className="products-carousel container">
            <h2 className="section-title text-uppercase text-left mb-4 pb-xl-2 mb-xl-4" style={{
                fontFamily: 'EUR42',
                color: "#743F05", letterSpacing: 3, fontWeight: 400, fontSize: 24
            }}>EQUILIBRIO NATURAL</h2>
            <Swiper
                className="swiper-container js-swiper-slider"
                slidesPerView={3}
                onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper: any) => console.log(swiper)}
                draggable
                navigation={{
                    nextEl: ".products-carousel__next",
                    prevEl: ".products-carousel__prev",
                }}
                pagination={{
                    el: ".products-carousel__scrollbar",
                }}
                loop
                breakpoints={{
                    "320": {
                        "slidesPerView": 2,
                        "slidesPerGroup": 1,
                        "spaceBetween": 14
                    },
                    "768": {
                        "slidesPerView": 2,
                        "slidesPerGroup": 2,
                        "spaceBetween": 24
                    },
                    "992": {
                        "slidesPerView": 4,
                        "slidesPerGroup": 1,
                        "spaceBetween": 50,
                        "pagination": false
                    }
                }}
            >
                <div className="position-relative">
                    <div className="swiper-wrapper">
                        {products.map((product: Product) => (
                            <SwiperSlide key={product.id} className="product-card">
                                <div className="pc__img-wrapper">
                                    <a href="./product1_simple.html">
                                        <img loading="lazy"
                                            src={process.env.REACT_APP_API_URL + (product.imagePath.length && product.imagePath[0]?.imagenPath || "")}
                                            width="330" height="400"
                                            alt={product.name + "1"} className="pc__img" />
                                    </a>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                </div>

            </Swiper>
            <div className="mb-3 mb-md-4 mb-xl-5 pb-2 pt-1"></div>
        </section >
    )
}
export default NaturalBalance
