import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
// import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { BannerItem } from "../../services/configService";
interface SectionSlideShowType {
  banners: BannerItem[]
}
const SectionSlideShow: React.FC<SectionSlideShowType> = ({ banners }) => {

  return (
    <>
      <Swiper
        className="swiper-container js-swiper-slider slideshow type2 full-width swiper-container-fade swiper-container-initialized swiper-container-horizontal swiper-container-ios"
        modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
        slidesPerView={1}
      >
        <SwiperSlide key={banners[0]?.id} className="overflow-hidden position-relative h-100">
          <div className="swiper-slide">
            <div className="overflow-hidden position-relative h-100">
              <div className="slideshow-bg">
                <img
                  loading="lazy"
                  src={process.env.REACT_APP_API_URL + banners[0]?.imagePath}
                  width="1903"
                  height="896"
                  alt="Pattern"
                  className="slideshow-bg__img object-fit-cover"
                  style={{ objectPosition: "70% center" }}
                />
              </div>
              <div className="slideshow-text container position-absolute start-50 top-50 translate-middle">
                <div
                  style={{
                    width: 507,
                    fontWeight: 400,
                    fontFamily: 'EUR42'
                  }}
                  className="text-uppercase h1 text-white mb-0 
                  animate animate_fade animate_btt animate_delay-4">
                  {banners[0]?.title}
                </div>
                <p style={{ fontWeight: 300, fontFamily: 'Montserrat' }} className="fs-6 mb-4 pb-2 text-white
                text-uppercase animate animate_fade animate_btt animate_delay-5">

                  {banners[0]?.description}
                </p>
                <button style={{ background: '#EFE2CF', fontWeight: 300, fontFamily: 'EUR42' }} className="btn btn-outline-primary border-0 fs-base text-uppercase animate animate_fade animate_btt animate_delay-7 btn-55">
                  <span style={{ color: "#743F05", letterSpacing: 4 }}>{banners[0]?.offer}</span>
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div className="slideshow-pagination position-left-center type2 align-items-center"></div>
      </Swiper >
      <div className="mb-3 mb-md-4 mb-xl-5 pb-2 pt-1"></div>
    </>
  );
};
export default SectionSlideShow;
