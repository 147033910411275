import { useEffect, useState } from "react";
import BlogCarousel from "../components/home/BlogCarousel";
import BrandsCarousel from "../components/home/BrandsCarousel";
import CartDrawer from "../components/home/CartDrawer";
import FooterWeb from "../components/home/FooterWeb";
import FooterMobile from "../components/home/FooterMobile";
import HeaderWeb from "../components/home/HeaderWeb";
import HeaderMobile from "../components/home/HeaderMobile";
import HeaderSvg from "../components/home/HeaderSvg";
import NewsletterPoup from "../components/home/NewsletterPoup";
import ProductCarousel from "../components/home/ProductCarousel";
import SectionSlideShow from "../components/home/SectionSlideShow";
import SiteMap from "../components/home/SiteMap";
import { Config, configuration } from "../services/configService";
import { Section, sectionsTypesPublished } from "../services/sectionsService";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useRequestContext } from "../services/contexts/useContextSweet";
import FullScreenLoader from "../components/utils/FullScreenLoader";
import HeaderTest from "../components/home/HeaderTest";
import NaturalBalance from "../components/home/NaturalBalance";
import NewSeason from "../components/home/NewSeason";
import ProductsNewSeason from "../components/home/ProductsNewSeason";
import IlainiKids from "../components/home/IlainiKids";
import FooterLayout from "../components/layouts/FooterLayout";

const Home = () => {

  const [config, setConfig] = useState<Config>();
  const [sections, setSections] = useState<Section[]>([]);
  const [{ }, { loadingHandler }] = useRequestContext();


  useEffect(() => {
    loadingHandler(true);
    initial()
  }, []);


  const initial = async () => {
    configuration(
      ({ data }) => setConfig(data),
      (err) => console.error(err)
    );
    sectionsTypesPublished(
      async ({ data }) => {
        setSections(data as Section[])
        await new Promise(resolve => setTimeout(resolve, 1200))
        loadingHandler(false)
      }
      ,
      (err) => console.error(err)
    );
  }
  return (
    <>
      <HeaderSvg />
      <HeaderMobile sections={sections}
        logo={config?.general.data.logoPath || ""} />
      <HeaderWeb sections={sections}
        logo={config?.general.data.logoPath || ""}
        configGeneral={config?.general} />
      <main>
        <SectionSlideShow banners={config?.banners || []} />
        <NaturalBalance />
        <NewSeason banners={config?.banners || []} />
        <ProductsNewSeason />
        <IlainiKids />
        {/* <TwoBlocks /> */}
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <img src={require('./../assets/images/nuestras_fibras.jpg')}
                alt="Nuestras Fibras" className="img-fluid" />
              {/* <div className="text-center">NUESTRAS FIBRAS</div> */}
              <button style={{ background: '#EFE2CF', fontWeight: 300, fontFamily: 'EUR42' }} className="btn btn-outline-primary border-0 fs-base text-uppercase animate animate_fade animate_btt animate_delay-7 btn-55">
                <span style={{ color: "#743F05", letterSpacing: 4 }}>
                  Niños
                </span>
              </button>
            </div>

            <div className="col-md-6 col-12 position-relative">
              <img src={require('./../assets/images/sale.jpg')} alt="Sale" className="img-fluid" />
              <div className="sale-text">
                <h1 className="text-white" style={{ fontFamily: 'EUR42', fontWeight: "400" }}>SALE</h1>
                <p style={{ fontFamily: 'Montserrat', fontSize: 24 }}>Prendas hasta con el <b> 50% </b>de descuento</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterLayout socials={config?.socials || []} configGeneral={config?.general} />
      {/* <FooterWeb socials={config?.socials || []} configGeneral={config?.general} />
      <FooterMobile /> */}
      <CartDrawer />
      <SiteMap />
      {config?.general.data.publishModal &&
        <NewsletterPoup configGeneral={config?.general} />
      }
      <div id="scrollTop" className="visually-hidden end-0"></div>
      <div className="page-overlay"></div>
      <FullScreenLoader />
      {/* <FloatingWhatsApp
        phoneNumber="123456789"
        accountName="Andrea"
        allowEsc
        allowClickAway
        notification
        notificationSound
        statusMessage="Normalmente responde dentro de 1 hora"
        chatMessage="¿En que te puedo ayudar?"
        messageDelay={1}
      /> */}
    </>
  );
};

export default Home;
