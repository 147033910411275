import { Product } from "../../services/productsService";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import Skeleton from "react-loading-skeleton";

import "swiper/css";
// import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useCurrencySweet } from "../../services/contexts/useCurrencySweet";
interface FooterPropsType {
  product?: Product;
}

const QuickView: React.FC<FooterPropsType> = ({ product }) => {
  const [
    { currencyInformation }, { }] = useCurrencySweet();
  return (
    <div className="modal fade" id="quickView" tabIndex={-1}>
      <div className="modal-dialog quick-view modal-dialog-centered">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="product-single">
            <div className="product-single__media m-0">
              <div className="product-single__image position-relative w-100">
                <Swiper
                  className="swiper-container js-swiper-slider"
                  hashNavigation={{
                    watchState: true,
                  }}
                  modules={[Navigation, Pagination, Autoplay, Scrollbar, A11y]}
                  navigation={{
                    nextEl: ".modal-dialog.quick-view .product-single__media .swiper-button-next",
                    prevEl: ".modal-dialog.quick-view .product-single__media .swiper-button-prev",
                  }}
                  loop={true}
                  draggable={true}
                  slidesPerGroup={1}
                  slidesPerView={1}
                >

                  {product?.imagePath.map(image => (
                    <SwiperSlide key={image.id} className="product-single__image-item">
                      <img
                        loading="lazy"
                        src={process.env.REACT_APP_API_URL + (product?.imagePath.length && image.imagenPath || "")}
                        alt=""
                      />
                    </SwiperSlide>

                  ))}
                  <div className="swiper-button-prev" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-992dc9acc837bb7f" aria-disabled="false">
                    <div>
                      <svg width="7" height="11" viewBox="0 0 7 11" xmlns="http://www.w3.org/2000/svg">
                        <use href="#icon_prev_sm"></use>
                      </svg></div>
                  </div>
                  <div className="swiper-button-next" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-992dc9acc837bb7f" aria-disabled="false">
                    <div>
                      <svg width="7" height="11" viewBox="0 0 7 11" xmlns="http://www.w3.org/2000/svg">
                        <use href="#icon_next_sm"></use>
                      </svg></div>
                  </div>
                  <div className="pb-2 mb-2 mb-xl-4"></div>
                  <div className="products-carousel__scrollbar swiper-scrollbar"></div>
                </Swiper>
              </div>
            </div>
            <div className="product-single__detail">
              <h1 className="product-single__name">
                {product?.name}
              </h1>
              <div className="product-single__price">
                <span className="current-price">{currencyInformation.code !== undefined ?
                  <span className="money price">{`${currencyInformation.code} ${currencyInformation.symbol} ${currencyInformation.local ? product?.saleLocalPrice : product?.saleInternationalPrice}`}</span>
                  : <Skeleton />}</span>
              </div>
              <div className="product-single__short-desc">
                <p>
                  {product?.description}
                </p>
              </div>
              <form name="addtocart-form" method="post">
                <div className="product-single__swatches">
                  <div className="product-swatch text-swatches">
                    <label>Tallas</label>
                    <div className="swatch-list">
                      {product?.sizes.map(size => (
                        <>
                          <input
                            onChange={(e) => console.warn(e)}
                            type="radio"
                            name="size"
                            id="swatch-1"
                          />
                          <label
                            className="swatch js-swatch"
                            htmlFor="swatch-1"
                            aria-label="Extra Small"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Extra Small"
                          >
                            {size.size}
                          </label>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="product-swatch color-swatches">
                    <label>Color</label>
                    <div className="swatch-list">
                      {product?.colors.map(color => (
                        <>
                          <input
                            onChange={(e) => console.warn(e)}
                            type="radio"
                            name="color"
                            id={color.id}
                          />
                          <img
                            width={25}
                            height={25}
                            style={{ borderRadius: '50%' }}
                            src={process.env.REACT_APP_API_URL + color.imagePath}
                            alt="Ilaini"
                            className="logo__image d-block"
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="product-single__addtocart">
                  <div className="qty-control position-relative">
                    <input
                      onChange={(e) => console.warn(e)}
                      type="number"
                      name="quantity"
                      value="1"
                      min="1"
                      className="qty-control__number text-center"
                    />
                    <div className="qty-control__reduce">-</div>
                    <div className="qty-control__increase">+</div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-addtocart js-open-aside"
                    data-aside="cartDrawer"
                  >
                    Añadir a la Cesta
                  </button>
                </div>
              </form>
              <div className="product-single__addtolinks">
              </div>
              <div className="product-single__meta-info mb-0">
                <div className="meta-item">
                  <label>Categoría:</label>
                  <span>{product?.type.name}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuickView;
