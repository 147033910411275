import { myFetch, HttpMethod } from "./baseService";
export type Establishment = {
  id?: number;
  description: string;
  departmentId: number;
  provinceId: number;
  districtId: number;
  address: string;
  email: string;
  telephone: number;
  state: boolean;
  createdAt: string;
  deletedAt: null;
  latitud: number;
  longitud: number;
  country: {
    id: string;
    description: string;
  };
};

export type GeneralResponse = {
  message: string;
  data: Establishment[];
};


// export const getEstablishments = async (
//   resultHandler: GeneralResponseHandler,
//   errorHandler: ErrorHandler
// ) =>
//   myFetch<GeneralResponse>(
//     "admin/establishments",
//     "",
//     resultHandler,
//     errorHandler,
//     HttpMethod.GET
//   );


export const getEstablishments = () => {
  return new Promise<Establishment[]>((resolve, reject) => {
    myFetch("admin/establishments", "",
      (response: GeneralResponse) => {
        resolve(response.data);
      },
      () => reject("error"),
      HttpMethod.GET
    );
  });
};