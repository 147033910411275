import { Outlet, useLocation } from 'react-router-dom';
import HeaderLayout from './HeaderLayout';
import HeaderMobile from './HeaderMobile';
import { useEffect, useState } from 'react';
import { Config, configuration } from '../../services/configService';
import { Section, sectionsTypesPublished } from '../../services/sectionsService';
import FooterLayout from './FooterLayout';
import FooterMobile from './FooterMobile';
import { ScrollToTop } from '../../scrollTop';
import { useRequestContext } from '../../services/contexts/useContextSweet';
import FullScreenLoader from '../utils/FullScreenLoader';

const Layout = () => {
    const [config, setConfig] = useState<Config>();
    const [sections, setSections] = useState<Section[]>([]);
    const [{ isLoading }, { loadingHandler }] = useRequestContext();


    const location = useLocation();


    useEffect(() => {
        const initial = () => {
            loadingHandler(true)
            sectionsTypesPublished(
                ({ data }) => setSections(data as Section[]),
                (err) => console.error(err)
            )

            configuration(
                ({ data }) => setConfig(data),
                (err) => console.error(err)
            ).then(() =>
                loadingHandler(false)
            )
        }
        initial()
        return () => {
            initial()
        };

    }, [location]);

    return (
        <div>
            {isLoading && <FullScreenLoader />}

            <HeaderMobile sections={sections} logo={config?.general.data.logoPath || ""} />
            <HeaderLayout sections={sections} logo={config?.general.data.logoPath || ""} configGeneral={config?.general} />
            <main>
                <ScrollToTop />
                <Outlet />
            </main>
            <FooterLayout socials={config?.socials || []} configGeneral={config?.general} />
            {/* <FooterMobile /> */}
        </div>
    );
};

export default Layout;
