import { createStore, createHook } from 'react-sweet-state';

export interface CurrencyInformation {
    ip?: string
    network?: string
    version?: string
    city?: string
    region?: string
    region_code?: string
    country?: string
    country_name?: string
    country_code?: string
    country_code_iso3?: string
    country_capital?: string
    country_tld?: string
    continent_code?: string
    in_eu?: boolean
    postal?: any
    latitude?: number
    longitude?: number
    timezone?: string
    utc_offset?: string
    country_calling_code?: string
    currency?: string
    currency_name?: string
    languages?: string
    country_area?: number
    country_population?: number
    asn?: string
    org?: string
    code: string
    symbol: string
    local: boolean
}


const Store = createStore({
    initialState: {
        currencyInformation: {} as CurrencyInformation,
    },
    actions: {
        setCurrencyInformation:
            (currencyInformation?: CurrencyInformation) =>
                ({ setState }) => {
                    setState({
                        currencyInformation
                    });
                },
    },
    name: 'useCurrencySweet'
});
export const useCurrencySweet = createHook(Store);
